<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { mapActions, mapGetters } from 'vuex'
import "prismjs";
import "prismjs/themes/prism.css";
import moment from "moment";
import Swal from "sweetalert2";
import Pagination from 'v-pagination-3';

export default {
  data() {
    return {
      title: "Users",
      selectedCountry: "",
      page: 1,
      perPage: 10,
      pages: [],
      filter: {
        page: 1,
        country: '',
        role: ''
      },
      limit: 3,
      isLoading: false
    };
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    Pagination
  },
  watch: {
    // totalData(){
    //   this.isLoading=true
    //         setTimeout(() => {
    //         this.isLoading=false
    //     }, 2000);
    // }
  },
  computed: {
    ...mapGetters({
      getUsers: 'users/getUsers',
      userData: "auth/user",
    }),
    totalData() {
      if (this.getUsers && this.getUsers.total && this.getUsers.data.length) {
        return this.getUsers.total
      } else {
        return 0
      }
    },
    displayedPosts() {
      return this.getUsers;
    },
    resultQuery() {
      if (this.selectedCountry) {
        const search = this.selectedCountry.toLowerCase();
        return this.displayedPosts.filter(user => user.country === search)
      } else {
        return this.displayedPosts;
      }
    },
    isAdmin() {
      return this.userData && this.userData.role_id == 1 ? true : false
    },
  },
  methods: {
    ...mapActions({
      fetchUsers: 'users/fetchUsers',
      deleteUser: 'users/deleteUser',
      setUserStatus: 'users/setUserStatus'
    }),
    getAllUsers(page) {
      this.fetchUsers(page)
    },
    setPages() {
      let numberOfPages = Math.ceil(this.getUsers.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
    getDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    deleteUserData(user) {
      Swal.fire({
        title: this.$t('t-are-you-sure'),
        text: this.$t('t-not-revert'),
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        cancelButtonText: this.$t('t-cancel'),
        confirmButtonColor: "#34c38f",
        confirmButtonText: this.$t('t-yes-delete-it'),
      }).then((result) => {
        if (result.value) {
          this.deleteUser(user)
            .then(() => {
              this.$toast.open({
                message: this.$t("user.user-deleted"),
                type: "success",
              })
              this.fetchUsersData(this.page)
            })
            .catch((e) => {
              console.log(e)
              Swal.fire("Oops...", "Something went wrong", "error");
            });
        }
      });
    },
    editUser(user) {
      console.log('user', user)
      if (user.role_id == 4 || user.role_id == 5) {
        this.$router.push({
          name: 'EditPartner',
          params: { id: user.id },
        });
      } else {
        this.$router.push({
          name: 'EditUser',
          params: { id: user.id },
        });
      }
    },
    fetchUsersData(page = 1) {
      this.filter.page = page
      console.log(page)
      this.page = page
      this.fetchUsers(this.filter)
    },
    updateUserStatus(user) {
      user.status = !user.status
      var payload = {
        id: user.id,
        status: user.status
      }
      this.$forceUpdate()

      this.setUserStatus(payload)
        .then(() => {
          this.$toast.open({
            message: 'Update status successfully',
            type: "success",
          })
          this.fetchUsersData(this.page)
        })
        .catch((e) => {
          console.log(e)
          Swal.fire("Oops...", "Something went wrong", "error");
        });
    }
  },
  async mounted() {
    await this.getAllUsers()
    await this.setPages()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('t-users')" />
    <div class="row">
      <div class="col-xl-12">
        <div class="filter">
          <div class="row">
            <div class="col-3">
              <Multiselect placeholder="Select Country" v-model="filter.country" :close-on-select="true"
                :searchable="true" :create-option="true" @select="fetchUsersData()"
                @clear="[filter.country = null, fetchUsersData()]" :options="[
                  { value: 'india', label: 'India' },
                  { value: 'uae', label: 'UAE' },
                ]" />
            </div>
            <div class="col-3">
              <Multiselect placeholder="Select Role" v-model="filter.role" :close-on-select="true" :searchable="true"
                :create-option="true" @select="fetchUsersData()" @clear="[filter.role = null, fetchUsersData()]" :options="[
                  { value: 1, label: 'Super Admin' },
                  { value: 2, label: 'Team' },
                  { value: 3, label: 'User' },
                  { value: 4, label: 'Consultant' },
                  { value: 5, label: 'QA' },
                ]" />
            </div>
            <!-- <div class="col-9 d-flex justify-content-end">
              <b-button
                variant="primary"
                class="btn btn-primary waves-effect waves-light"
                >Filter</b-button
              >
            </div> -->
          </div>
        </div>
        <div class="card mt-4">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-users") }}</h4>
            <div class="flex-shrink-0">
              <router-link to="/admin/users/add" class="btn btn-primary waves-effect waves-light">
                {{ $t('user.add-user') }}
              </router-link>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <div class="table-responsive table-card">

              <!-- <table class="table align-middle table-nowrap" id="customerTable"> -->
              <table class=" table align-middle table-nowrap" id="customerTable">
                <thead class="table-light">
                  <tr>
                    <th class="sort" scope="col">{{ $t('user.id') }}</th>
                    <th class="sort" scope="col">{{ $t('user.name') }}</th>
                    <th class="sort" scope="col">{{ $t('user.email') }}</th>
                    <th class="sort" scope="col">{{ $t('user.role') }}</th>
                    <th class="sort" scope="col">{{ $t('user.phone') }}</th>
                    <th class="sort" scope="col">{{ $t('user.date') }}</th>
                    <th class="sort" scope="col">{{ $t('user.country') }}</th>
                    <th scope="col">{{ $t('user.action') }}</th>
                  </tr>
                </thead>
                <!--end thead-->
                <tbody class="list form-check-all">
                  <tr v-for="(data, index) of getUsers.data" :key="index">
                    <td class="id">
                      100785 {{ data.id }}
                    </td>
                    <td>{{ data.name }}</td>
                    <td class="pairs">{{ data.email }}</td>
                    <td class="high">{{ data.role_id == 1 ? 'Super Admin' : data.role_id == 2 ? 'Team' : data.role_id == 3
                      ? 'User' : data.role_id == 4 ? 'Consultant' : data.role_id == 5 ? 'QA' : '' }}</td>
                    <td class="low">{{ data.phone }}</td>
                    <td>{{ getDate(data.created_at) }}</td>
                    <td class="market_cap">{{ data.country }}</td>
                    <td>
                      <div class="hstack gap-3 flex-wrap">
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" :checked="data.status ? true : false"
                            role="switch" id="flexSwitchCheckDefault" @change="updateUserStatus(data)">
                        </div>
                        <a href="javascript:void(0);" @click="editUser(data)" class="link-success fs-15"><i
                            class="ri-edit-2-line"></i></a><a v-if="isAdmin" href="javascript:void(0);"
                          @click="deleteUserData(data)" class="link-danger fs-15"><i class="ri-delete-bin-line"></i></a>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="getUsers && getUsers.data && getUsers.data.length == 0">
                    <td colspan="8" class="text-center">No Data Found.</td>
                  </tr>
                </tbody>
                <!--end tbody-->
                <!-- </table> -->
              </table>
            </div>
            <div class="d-flex justify-content-start mt-3 w-100">
              <pagination v-model="page" :records="totalData" :per-page="perPage" @paginate="fetchUsersData"
                class="pagination-wrap" />
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <!-- end col -->
    </div>

    <!--end row-->
  </Layout>
</template>
